import { gql } from '@apollo/client';

export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on Image {
    uuid
    filePath
    cropRectangle {
      topLeftXPosition
      topLeftYPosition
      bottomRightXPosition
      bottomRightYPosition
    }
    imageUrl
    width
    height
  }
`;

export const PAGINATOR_FRAGMENT = gql`
  fragment PaginatorFragment on Paginator {
    itemsCount
    itemsPerPage
    currentPage
    lastPage
    offset
    countOfItemsOnCurrentPage
    isCurrentPageFirst
    isCurrentPageLast
  }
`;

export const FILTER_CHOICE_FRAGMENT = gql`
  fragment FilterChoiceFragment on FilterChoice {
    label
    value
    count
    webalizedLabel
  }
`;
