import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { getProfileDetailUrlRaw } from '@libs/getProfileDetailUrl';
import { ProfileUrlGenerator } from '../../../generated/phpConstants';
import { ProfileTabName } from '../../../graphql/generated/types/graphqlTypes';

const getTabNameFromTabSlug = (tabSlug: string, locale: string): ProfileTabName | null => {
  for (const [tabName, slugsByLocale] of Object.entries(ProfileUrlGenerator.TAB_SLUGS_BY_TAB_NAME_AND_LOCALE)) {
    if (slugsByLocale?.[locale as keyof typeof slugsByLocale] === tabSlug) {
      return tabName as ProfileTabName;
    }
  }

  return null;
};

export const parseProfileSlug = (query: ParsedUrlQuery, locale: string) => {
  const profileIdAndWebalizedName = query?.profileSlug?.[0] || '';
  const profileIdAndWebalizedNameMatches = profileIdAndWebalizedName.match(/([0-9]+)(?:-(.*))?/);
  const profileId = Number(profileIdAndWebalizedNameMatches?.[1]);

  const tabSlug = query?.profileSlug?.[1] || '';

  return {
    profileId: !Number.isNaN(profileId) ? profileId : null,
    webalizedProfileName: profileIdAndWebalizedNameMatches?.[2] || null,
    tabName: getTabNameFromTabSlug(tabSlug, locale),
    currentPage: Number(query?.profileSlug?.[2] || 1),
  };
};

export const useCurrentProfile = () => {
  const { query } = useRouter();
  const { i18n } = useTranslation();

  const parsedProfileSlug = parseProfileSlug(query, i18n.language);

  return {
    ...parsedProfileSlug,
    profileRoute: getProfileDetailUrlRaw(
      i18n.language,
      parsedProfileSlug.profileId,
      parsedProfileSlug.webalizedProfileName,
    ),
  };
};
