import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useClickAway } from 'react-use';
import useCompanyOverview from '@components/Profile/hooks/useCurrentProfileOverview';
import { DEBOUNCE_MILLISECONDS_FOR_AUTOCOMPLETE, useDebounceValue } from '@hooks/useDebounceValue';
import CrossSvg from '@icons/cross-deprecated.svg';
import SearchSvg from '@icons/search.svg';
import { getProfileDetailUrlRaw } from '@libs/getProfileDetailUrl';
import {
  ProfileSearchQuery,
  ProfileSearchQueryVariables,
  ProfileSearchSuggestQuery,
  ProfileSearchSuggestQueryVariables,
} from 'graphql/generated/types/graphqlTypes';
import { PROFILE_SEARCH, PROFILE_SEARCH_SUGGEST } from 'graphql/queries/companySearch';
import { useGenerateRoute } from 'libs/localizedRoute';
import classes from './CompanySearch.module.scss';
import { ProfileSearchSuggestionType } from './types';
import { CookiesConstants } from '../../../generated/phpConstants';
import CompanySearchResults from '../CompanySearchResults/CompanySearchResults';

const keyCodes = {
  KEY_ENTER: 13,
  KEY_SPACE: 32,
  KEY_UP: 38,
  KEY_DOWN: 40,
};

export const NOTHING_SELECTED_RESULT_INDEX = -1;

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  handleSetIsSearchOpen: (isSearchOpen: boolean) => void;
  initialSearchQuery?: string;
  isMobileSearchActive: boolean;
  showMatejRecommendations?: boolean;
}

const CompanySearch = ({
  className,
  handleSetIsSearchOpen,
  initialSearchQuery = '',
  isMobileSearchActive,
  showMatejRecommendations = true,
  ...htmlProps
}: Props) => {
  const { data: profileData } = useCompanyOverview();
  const ref = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t, i18n } = useTranslation(['messages', 'profile']);
  const generateRoute = useGenerateRoute();
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const debouncedSearchQuery = useDebounceValue(searchQuery, DEBOUNCE_MILLISECONDS_FOR_AUTOCOMPLETE);
  const [isFocused, setIsFocused] = useState(false);
  const [results, setResult] = useState<ProfileSearchSuggestionType[]>([]);
  const router = useRouter();

  useClickAway(ref, () => {
    setIsFocused(false);
  });

  const [selectedResultIndex, setSelectedResultIndex] = useState<number>(NOTHING_SELECTED_RESULT_INDEX);
  const [cookies] = useCookies();

  const { data: profileSearchSuggestData } = useQuery<ProfileSearchSuggestQuery, ProfileSearchSuggestQueryVariables>(
    PROFILE_SEARCH_SUGGEST,
    {
      variables: {
        currentProfileUrlIdentifier: profileData?.profileOverview?.profile.urlIdentifier,
        lmcCookieId: cookies[CookiesConstants.LMC_USER_IDENTIFIER_COOKIE],
      },
      skip: searchQuery.length > 2 || cookies[CookiesConstants.LMC_USER_IDENTIFIER_COOKIE] === undefined,
      onCompleted: (data) => {
        if (data.profileSearchSuggest) {
          setResult(data?.profileSearchSuggest as any);
        }
      },
    },
  );

  useQuery<ProfileSearchQuery, ProfileSearchQueryVariables>(PROFILE_SEARCH, {
    variables: {
      searchTerm: debouncedSearchQuery,
    },
    skip: debouncedSearchQuery.length < 2,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.profileSearch) {
        setResult(data?.profileSearch as any);
      }
    },
    context: { useApolloNetworkStatus: false },
  });

  const onClickOnResult = (profile: ProfileSearchSuggestionType) => {
    setIsFocused(false);
    setSearchQuery('');
    handleSetIsSearchOpen(false);

    // TODO: Get actual webalized name
    const webalizedName = null;

    router.push(getProfileDetailUrlRaw(i18n.language, Number(profile.companyId), webalizedName));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value || '');
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const onSearchButtonClick = () => {
    window.location.assign(generateRoute('companySearch.search', { q: searchQuery }));
  };

  const getMaxSelectableIndex = () => {
    return results.length - 1;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isShowingMatejSuggestions = searchQuery.length === 0 && showMatejRecommendations;
    switch (event.keyCode) {
      case keyCodes.KEY_UP:
        if (selectedResultIndex > 0) {
          setSelectedResultIndex((prevState) => prevState - 1);
        }

        event.preventDefault();
        break;

      case keyCodes.KEY_DOWN:
        if (selectedResultIndex < getMaxSelectableIndex()) {
          setSelectedResultIndex((prevState) => prevState + 1);
        }

        event.preventDefault();
        break;

      case keyCodes.KEY_ENTER:
        if (isShowingMatejSuggestions && selectedResultIndex !== NOTHING_SELECTED_RESULT_INDEX) {
          onClickOnResult(results[selectedResultIndex]);
        }

        if (searchQuery.length > 0 && selectedResultIndex !== NOTHING_SELECTED_RESULT_INDEX) {
          onClickOnResult(results[selectedResultIndex]);
        }

        if (searchQuery.length > 0 && selectedResultIndex === NOTHING_SELECTED_RESULT_INDEX) {
          onSearchButtonClick();
        }

        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (searchQuery.length < 2 && profileSearchSuggestData?.profileSearchSuggest?.length) {
      setResult(profileSearchSuggestData?.profileSearchSuggest as any);
    }
  }, [searchQuery, profileSearchSuggestData?.profileSearchSuggest]);

  useEffect(() => {
    if (isMobileSearchActive) {
      inputRef.current?.focus();
    }
  }, [inputRef, isMobileSearchActive]);

  return (
    <div
      {...htmlProps}
      ref={ref}
      className={classNames(classes.CompanySearch, isMobileSearchActive && classes.CompanySearchExpanded, className)}
      data-cy="company-search-container"
    >
      <input
        type="search"
        ref={inputRef}
        className={classes.CompanySearch__input}
        value={searchQuery}
        placeholder={t('COMPANY_SEARCH_PLACEHOLDER')}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        aria-label={t('SEARCH_COMPANY_ON_THE_INTERNET', { ns: 'profile' })}
      />
      <SearchSvg />
      <button type="button" onClick={() => handleSetIsSearchOpen(false)} className={classes.CompanySearch__close}>
        <CrossSvg />
      </button>
      {isFocused && (
        <CompanySearchResults
          onClickOnResult={onClickOnResult}
          results={results}
          searchQuery={searchQuery}
          selectedResultIndex={selectedResultIndex}
        />
      )}
    </div>
  );
};

export default CompanySearch;
