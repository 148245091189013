import { UrlObject } from 'url';
import { Button, SpiritButtonLinkProps } from '@lmc-eu/spirit-web-react';
import { SpiritButtonProps } from '@lmc-eu/spirit-web-react/types';
import Link from 'next/link';
import { ReactNode } from 'react';

type OmittedProps = 'href' | 'UNSAFE_className';
type Url = string | UrlObject;

interface Props extends Omit<SpiritButtonProps & SpiritButtonLinkProps, OmittedProps> {
  children: ReactNode;
  href: Url;
  scroll?: boolean;
  shallow?: boolean;
  className?: string;
}

const NextButtonLink = ({ children, href, scroll, shallow = false, className, rel, ...spiritButtonProps }: Props) => {
  if (spiritButtonProps.isDisabled || href === '') {
    return (
      <Button {...spiritButtonProps} UNSAFE_className={className}>
        {children}
      </Button>
    );
  }

  return (
    <Link href={href} shallow={shallow} rel={rel} scroll={scroll}>
      <Button {...spiritButtonProps} UNSAFE_className={className}>
        {children}
      </Button>
    </Link>
  );
};

export default NextButtonLink;
