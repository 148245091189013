import { useQuery } from '@apollo/client';
import { LOGGED_IN_USER } from 'graphql/queries/user';
import { LoggedInUserQuery, LoggedInUserQueryVariables } from '../graphql/generated/types/graphqlTypes';

export const useUser = () => {
  const { data, previousData } = useQuery<LoggedInUserQuery, LoggedInUserQueryVariables>(LOGGED_IN_USER);

  const userData = data || previousData;

  return userData?.loggedInUser;
};
