import { gql } from '@apollo/client';

export const REVIEW_DATA_FRAGMENT = gql`
  fragment ReviewDataFragment on ReviewData {
    id
    questionnaireId
    employerUnitName
    leftCompanyAgo
    currentlyEmployed
    howLongWithCompanyLabel
    specializationLabel
    valueBulb
    valueDiamond
    replyText
    currentUserVote
    elaborationAnswerData {
      text
      label
    }
    reviewUnconfirmed
    howLongAgoAdded
    monthAndYearOfFinishFormatted
    numberOfVotes {
      positive
      negative
    }
    isPinned
  }
`;
