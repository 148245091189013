import { useTranslation } from 'next-i18next';
import React from 'react';
import Dropdown from '@components/utilities/Dropdown/Dropdown';
import DropdownItem from '@components/utilities/DropdownItem/DropdownItem';
import DropdownTitle from '@components/utilities/DropdownTitle/DropdownTitle';
import classes from './CompanySearchResults.module.scss';
import { ProfileSearchSuggestionType } from '../CompanySearch/types';

interface Props extends Omit<React.ComponentPropsWithoutRef<'div'>, 'results'> {
  onClickOnResult: (result: ProfileSearchSuggestionType) => void;
  results: ProfileSearchSuggestionType[];
  searchQuery?: string;
  selectedResultIndex: number;
}

const CompanySearchResults = ({ onClickOnResult, results, searchQuery, selectedResultIndex, ...divProps }: Props) => {
  const { t } = useTranslation(['messages']);
  const onMouseClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    result: ProfileSearchSuggestionType,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onClickOnResult(result);
  };

  if (!results?.length) return null;

  return (
    <div className={classes.CompanySearchResults} {...divProps}>
      <Dropdown>
        {(!searchQuery || searchQuery?.length < 2) && (
          <DropdownTitle>{t('COMPANY_SEARCH_DROPDOWN_MENU_HEADING_TEXT')}</DropdownTitle>
        )}
        {results.map((result, index) => {
          return (
            <DropdownItem
              onClick={(event) => onMouseClick(event, result)}
              isSelected={index === selectedResultIndex}
              key={result.companyId}
            >
              <span className="text-primary" data-cy="company-search-dropdown-item-company-name">
                {result.companyName}
              </span>
              {result.numberOfReviews
                ? result.numberOfReviews > 0 && (
                    <span className="text-nowrap text-secondary">
                      {t('NUMBER_OF_REVIEWS', { count: result.numberOfReviews })}
                    </span>
                  )
                : null}
            </DropdownItem>
          );
        })}
      </Dropdown>
    </div>
  );
};

export default CompanySearchResults;
