import classNames from 'classnames';
import React, { ReactNode } from 'react';
import classes from './Dropdown.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  scrollable?: boolean;
}

const Dropdown = ({ children, className, scrollable, ...divProps }: Props) => (
  <div className={classNames(className, classes.Dropdown, scrollable ? classes.DropdownScrollable : '')} {...divProps}>
    {children}
  </div>
);

export default Dropdown;
