import { Container } from '@lmc-eu/spirit-web-react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import HamburgerMenu, { MenuItemTypes, MenuItemUnionType } from '@components/HamburgerMenu/HamburgerMenu';
import NavbarHamburgerMenuToggle from '@components/HamburgerMenu/NavbarHamburgerMenuToggle';
import CompanySearch from '@components/utilities/CompanySearch/CompanySearch';
import Counter from '@components/utilities/Counter/Counter';
import Gradient from '@components/utilities/Gradient/Gradient';
import Logo from '@components/utilities/Logo/Logo';
import NextButtonLink from '@components/utilities/NextButtonLink/NextButtonLink';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';
import { useUser } from '@hooks/useUser';
import SearchSvg from '@icons/search.svg';
import pushGAInteractionEvent from '@libs/ga/pushGAInteractionEvent';
import { useGenerateRoute, useGetLocalizedRoute } from '@libs/localizedRoute';
import { Route } from '@libs/routes';
import { getUrlWithParameters } from '@libs/urlUtils';
import classes from './Navbar.module.scss';
import { InterestingProfilesGalleryController } from '../../generated/phpConstants';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  empty?: boolean;
}

const Navbar = ({ className, empty, ...divProps }: Props) => {
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const { t } = useTranslation(['messages', 'profile', 'front']);
  const getLocalizedRoute = useGetLocalizedRoute();
  const generateRoute = useGenerateRoute();
  const { isMobile, isTablet, isDesktop } = useActiveBreakpoint();
  const user = useUser();
  const router = useRouter();

  useEffect(() => {
    if (!isMobile) {
      setIsMobileSearchOpen(false);
    }
  }, [isMobile]);

  const getLoginWithPasswordRouteWithTargetPath = (): string => {
    if (router.pathname === Route.loginWithPassword) {
      return getLocalizedRoute(Route.loginWithPassword);
    }

    return getUrlWithParameters(getLocalizedRoute(Route.loginWithPassword), { targetPath: router.asPath });
  };

  const hamburgerMenuItems: MenuItemUnionType[] = [];

  if (!isDesktop) {
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('CHECK_OUT_THESE_COMPANIES'),
      url: getUrlWithParameters(getLocalizedRoute(Route.interestingProfileGallery), {
        zdroj: 'menu',
        premium: InterestingProfilesGalleryController.COUNT_OF_PREMIUM_COMPANIES_TO_SHOW,
      }),
    });
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('ADD_REVIEW', { ns: 'profile' }),
      url: generateRoute('front.questionnaire.basic', { zdroj: 'atmoskop-web', typ: 'atmoskop-web' }),
      rel: 'nofollow',
    });

    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('FOOTER_BUTTON_COMPANY_FAQ', { ns: 'front' }),
      url: generateRoute('front.premiumLandingPage.premiumLandingPage'),
    });

    if (!isTablet) {
      hamburgerMenuItems.unshift({
        type: MenuItemTypes.Link,
        title: t('JOB_OPPORTUNITIES', { ns: 'profile' }),
        url: generateRoute('front.job_description.list'),
      });
    }

    hamburgerMenuItems.push({
      type: MenuItemTypes.Line,
    });
  }

  if (user) {
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: user.email!,
      url: '',
      disabled: true,
    });
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('RECOMMENDED_COMPANIES'),
      url: getLocalizedRoute(Route.profileMatchingResult),
      rel: 'nofollow',
    });
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('MY_SUBSCRIBED_COMPANIES'),
      url: generateRoute('front.userAccount.manageSubscriptions'),
    });
    if (user?.isB2bContactPerson) {
      hamburgerMenuItems.push({
        type: MenuItemTypes.Link,
        title: t('COMPANY_ADMINISTRATION'),
        url: getLocalizedRoute(Route.b2bAdminMonthlyReport),
      });
    }
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('LOGOUT'),
      url: generateRoute('front.logout'),
      isLinkPrimary: true,
      onClick: () => {
        pushGAInteractionEvent('user.logout', 'user', 'user.logout', 'window.atmoskop.userId');
      },
      dataCyItem: 'logout-button',
    });
  } else {
    hamburgerMenuItems.push({
      type: MenuItemTypes.Link,
      title: t('LOGIN_BUTTON_LABEL'),
      url: getLoginWithPasswordRouteWithTargetPath(),
      isLinkPrimary: true,
      dataCyItem: 'login-button',
      rel: 'nofollow',
    });
  }

  return (
    <div {...divProps} className={classNames(classes.Navbar, className)}>
      <Gradient />
      <Container>
        <div className={classNames(classes.Navbar__contentWrapper, !empty && classes.Navbar__contentWrapperPopulated)}>
          <Link href={generateRoute('homepage')}>
            <Logo />
          </Link>
          {!empty && (
            <div className={classes.Navbar__content}>
              {!isMobileSearchOpen && (
                <button
                  type="button"
                  className={classNames(classes.Navbar__button, classes.Navbar__buttonSearch, 'mr-500')}
                  onClick={() => setIsMobileSearchOpen((prevState) => !prevState)}
                >
                  <SearchSvg />
                </button>
              )}
              <div
                className={classNames(
                  classes.Navbar__searchWrapper,
                  isMobileSearchOpen && classes.Navbar__searchWrapperExpanded,
                )}
              >
                <CompanySearch
                  handleSetIsSearchOpen={setIsMobileSearchOpen}
                  isMobileSearchActive={isMobileSearchOpen}
                />
              </div>
              <div className="d-none d-desktop-flex align-items-center mr-tablet-700">
                <a
                  href={generateRoute('front.profileGallery.gallery', {
                    zdroj: 'menu',
                    premium: InterestingProfilesGalleryController.COUNT_OF_PREMIUM_COMPANIES_TO_SHOW,
                  })}
                  className="link-secondary"
                >
                  {t('CHECK_OUT_THESE_COMPANIES')}
                  <Counter>{InterestingProfilesGalleryController.FULL_GALLERY_PROFILES_COUNT}</Counter>
                </a>
              </div>
              <div className="d-none d-desktop-flex align-items-center mr-tablet-700">
                <a
                  href={generateRoute('front.questionnaire.basic', { zdroj: 'atmoskop-web', typ: 'atmoskop-web' })}
                  rel="nofollow"
                  className="link-secondary"
                >
                  {t('ADD_REVIEW', { ns: 'profile' })}
                </a>
              </div>
              <div className="d-none d-tablet-flex mr-tablet-700">
                <NextButtonLink href={getLocalizedRoute(Route.jobDescriptionList)} color="primary" size={'large'}>
                  {t('GO_TO_JOB_DESCRIPTIONS_LIST')}
                </NextButtonLink>
              </div>
              <div className="d-desktop-flex align-items-center mr-desktop-700">
                <HamburgerMenu menuItems={hamburgerMenuItems} icon={<NavbarHamburgerMenuToggle />} />
              </div>
              <div className="d-none d-desktop-flex align-items-center">
                <a href={getLocalizedRoute(Route.informationForCompanies)} className="link-secondary">
                  {t('FOOTER_BUTTON_COMPANY_FAQ', { ns: 'front' })}
                </a>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
