import { gql } from '@apollo/client';

export const PROFILE_SEARCH = gql`
  query ProfileSearch($searchTerm: String!) {
    profileSearch(searchTerm: $searchTerm) {
      companyName
      numberOfReviews
      companyId
      companyGroupId
    }
  }
`;

export const PROFILE_SEARCH_SUGGEST = gql`
  query ProfileSearchSuggest($lmcCookieId: String!, $currentProfileUrlIdentifier: String) {
    profileSearchSuggest(lmcCookieId: $lmcCookieId, currentProfileUrlIdentifier: $currentProfileUrlIdentifier) {
      companyName
      numberOfReviews
      companyId
      companyGroupId
    }
  }
`;
