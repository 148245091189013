import classNames from 'classnames';
import React, { ReactNode, useId } from 'react';
import classes from './DropdownItem.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  isSelected?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  dataCyItem?: string;
  isInGroup?: boolean;
}

const DropdownItem = ({
  children,
  className,
  isSelected,
  onClick,
  disabled,
  dataCyItem,
  isInGroup,
  ...buttonProps
}: Props) => {
  const id = useId();

  return (
    <button
      className={classNames(
        classes.DropdownItem,
        isSelected && classes.DropdownItemSelected,
        isInGroup && classes.DropdownItemInGroup,
        disabled && 'text-secondary',
        className,
      )}
      onClick={onClick}
      key={id}
      {...buttonProps}
      disabled={disabled}
      data-cy="dropdown-item"
      data-cy-item={dataCyItem}
    >
      {children}
    </button>
  );
};

export default DropdownItem;
