import classNames from 'classnames';
import React from 'react';
import classes from './Gradient.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  reverse?: boolean;
}

const Gradient = ({ className, reverse = true, ...divProps }: Props) => (
  <div {...divProps} className={classNames(classes.Gradient, className, reverse && classes.GradientReverse)} />
);

export default Gradient;
