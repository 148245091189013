import classNames from 'classnames';
import React from 'react';
import { OptionProps, components } from 'react-select';
import TickSVG from '@icons/tick.svg';
import classes from './CustomOption.module.scss';

const CustomOption = (props: OptionProps) => (
  <div data-cy="next-select-option">
    <components.Option
      className={classNames(
        classes.CustomOption,
        props.isDisabled && !props.isSelected ? classes.CustomOptionIsDisabled : '',
      )}
      {...props}
    >
      <div className={classes.CustomOption__text}>{props.children}</div>
      {props.isSelected && (
        <span className={classes.CustomOptionIsSelected} data-cy="next-select-option-is-selected">
          <TickSVG />
        </span>
      )}
    </components.Option>
  </div>
);

export default CustomOption;
