import React from 'react';
import { InputProps, components } from 'react-select';
import classes from './CustomInput.module.scss';

const CustomInput = (props: InputProps) => {
  const { customMultiValueAddMorePlaceholder }: any = props.selectProps;
  const showCustomMultiValueAddMorePlaceholder = props.isMulti && props.hasValue && customMultiValueAddMorePlaceholder;

  return (
    <div
      className={
        showCustomMultiValueAddMorePlaceholder
          ? classes.CustomInput__multiValueInputContainer
          : classes.CustomInput__defaultInputContainer
      }
    >
      <components.Input {...props} />
      {showCustomMultiValueAddMorePlaceholder && (
        <span className={classes.CustomInput__multiValuePlaceholder}>{customMultiValueAddMorePlaceholder}</span>
      )}
    </div>
  );
};

export default CustomInput;
