import classNames from 'classnames';
import React from 'react';
import classes from './Counter.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  disabled?: boolean;
}

const Counter = ({ children, className, disabled, ...divProps }: Props) => (
  <span className={classNames(classes.Counter, disabled && classes.CounterDisabled, className)} {...divProps}>
    {children}
  </span>
);

export default Counter;
