import classNames from 'classnames';
import React, { ReactNode, useId } from 'react';
import classes from './DropdownTitle.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
}

const DropdownTitle = ({ children, className }: Props) => {
  const id = useId();

  return (
    <div className={classNames(classes.DropdownItem, className)} key={id}>
      {children}
    </div>
  );
};

export default DropdownTitle;
