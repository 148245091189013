const pushGAInteractionEvent = (
  event: string,
  category: string,
  action: string,
  label: string,
  value: number = 0,
  additionalProperties = {},
) => {
  const dataLayer = window.dataLayer || [];

  dataLayer.push({
    event,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    // defaults are values from previous events unless overwritten
    eventValue: value,
    eventNonInteraction: false,
    ...additionalProperties,
  });
};

export default pushGAInteractionEvent;
