import classNames from 'classnames';
import React from 'react';
import classes from '@components/HamburgerMenu/HamburgerMenu.module.scss';
import HamburgerMenuBarsIcon from '@components/HamburgerMenu/HamburgerMenuBarsIcon';
import HamburgerMenuUserIcon from '@components/HamburgerMenu/HamburgerMenuUserIcon';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  onClick?: () => void;
  isMenuOpen?: boolean;
}

const NavbarHamburgerMenuToggle = ({ onClick, isMenuOpen, ...buttonProps }: Props) => {
  const { isDesktop } = useActiveBreakpoint();

  return (
    <button
      className={classNames(classes.HamburgerMenu__hamburger, isMenuOpen && classes.HamburgerMenu__hamburgerOpen)}
      onClick={onClick}
      data-cy="hamburger-menu-toggle"
      {...buttonProps}
    >
      {isDesktop ? <HamburgerMenuUserIcon /> : <HamburgerMenuBarsIcon />}
    </button>
  );
};

export default NavbarHamburgerMenuToggle;
