import React from 'react';
import { DropdownIndicatorProps, components } from 'react-select';
import ChevronDownSvg from '@icons/chevron-down.svg';
import classes from './CustomDropdownlndicator.module.scss';

const CustomDropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props} className={classes.CustomDropdownIndicator}>
      <ChevronDownSvg />
    </components.DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
