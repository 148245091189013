import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCurrentProfile } from '@components/Profile/hooks/useCurrentProfile';
import {
  ProfileContractType,
  ProfileOverviewQuery,
  ProfileOverviewQueryVariables,
} from 'graphql/generated/types/graphqlTypes';
import { PROFILE_OVERVIEW, createProfileOverviewQueryVariables } from 'graphql/queries/profile';

const useCurrentProfileOverview = () => {
  const { profileId } = useCurrentProfile();
  const { query } = useRouter();

  const queryResult = useQuery<ProfileOverviewQuery, ProfileOverviewQueryVariables>(PROFILE_OVERVIEW, {
    variables: createProfileOverviewQueryVariables(profileId!, query),
    skip: !profileId,
  });

  const contract = queryResult.data?.profileOverview?.latestActiveContract?.type || ProfileContractType.TypeNoContract;

  return { ...queryResult, contract };
};

export default useCurrentProfileOverview;
