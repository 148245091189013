import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import FlagCzechSvg from '@icons/flag-cz.svg';
import FlagEnglishSvg from '@icons/flag-english.svg';
import classes from './LanguageSwitcher.module.scss';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation(['messages']);
  const { asPath } = useRouter();
  let targetLocale = 'en';
  let targetLocaleIcon = <FlagEnglishSvg />;
  let targetUrl = asPath;

  if (i18n.language === 'en') {
    targetLocale = 'cs';
    targetLocaleIcon = <FlagCzechSvg />;
  }

  if (targetLocale === 'en') {
    targetUrl = `/en${targetUrl}`;
  }

  return (
    <div>
      <a href={targetUrl} className={classes.LanguageSwitcher__linkWithFlag} rel="nofollow">
        <span>{t('SWITCH_TO_LOCALE', { lng: targetLocale })}</span>
        {targetLocaleIcon}
      </a>
    </div>
  );
};

export default LanguageSwitcher;
