import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export const DEBOUNCE_MILLISECONDS_FOR_AUTOCOMPLETE = 300;

export const useDebounceValue = <T>(value: T, waitInMilliseconds: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const debouncedSetDebouncedValue = useMemo(
    () =>
      debounce((val: T) => {
        setDebouncedValue(val);
      }, waitInMilliseconds),
    [setDebouncedValue],
  );

  useEffect(() => {
    debouncedSetDebouncedValue(value);
  }, [value]);

  return debouncedValue;
};
