import { Route } from '@libs/routes';
import { getLocalizedRoute, getLocalizedTabSlug } from './localizedRoute';
import { AbstractProfile, ProfileTabName } from '../graphql/generated/types/graphqlTypes';

export const getProfileDetailUrlRaw = (
  locale: string,
  profileId: number | null,
  webalizedName: string | null,
  tabName?: ProfileTabName | null,
) => {
  const localizedRoute = getLocalizedRoute(Route.companyProfile, locale);

  let url = `${localizedRoute}/${profileId}-${webalizedName}`;
  if (tabName != null) {
    url += getLocalizedTabSlug(tabName);
  }

  return url;
};

export const getProfileDetailUrl = (locale: string, profile: AbstractProfile, tabName?: ProfileTabName | null) => {
  return getProfileDetailUrlRaw(locale, profile.id, profile.webalizedName, tabName);
};
