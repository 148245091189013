import React from 'react';
import { IndicatorsContainerProps, components } from 'react-select';
import ChevronDownSvg from '@icons/chevron-down.svg';
import classes from './CustomIndicatorsContainer.module.scss';

const CustomIndicatorsContainer = (props: IndicatorsContainerProps) => {
  return (
    <components.IndicatorsContainer {...props}>
      {props.children}
      <div className={classes.CustomIndicatorsContainer}>
        <ChevronDownSvg />
      </div>
    </components.IndicatorsContainer>
  );
};

export default CustomIndicatorsContainer;
