import classNames from 'classnames';
import React from 'react';
import classes from '@components/HamburgerMenu/HamburgerMenu.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

const HamburgerMenuBarsIcon = ({ className, ...divProps }: Props) => (
  <div {...divProps} className={classNames(className, classes.HamburgerMenu__barsWrapper)}>
    <div className={classes.HamburgerMenu__bar} />
    <div className={classes.HamburgerMenu__bar} />
    <div className={classes.HamburgerMenu__bar} />
  </div>
);

export default HamburgerMenuBarsIcon;
