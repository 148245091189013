import { ClearIndicatorProps, components } from 'react-select';
import CrossSvg from '@icons/cross-deprecated.svg';

const CustomClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <CrossSvg />
    </components.ClearIndicator>
  );
};

export default CustomClearIndicator;
