import classNames from 'classnames';
import React from 'react';
import { ControlProps, components } from 'react-select';
import classes from './CustomControl.module.scss';

const CustomControl = ({ children, className, ...props }: ControlProps) => {
  return (
    <div data-cy="next-select-control">
      <components.Control
        className={classNames(classes.CustomControl, props.menuIsOpen ? classes.CustomControlFocused : '', className)}
        {...props}
      >
        {props.selectProps.icon && <div className={classes.CustomControl__icon}>{props.selectProps.icon}</div>}
        {children}
      </components.Control>
    </div>
  );
};

export default CustomControl;
