import { gql } from '@apollo/client';

export const JOB_DESCRIPTION_LINK_FRAGMENT = gql`
  fragment JobDescriptionLinkFragment on JobDescriptionLink {
    g2id
    recruitmentProcessId
    title
    externalDetailUrl
    isDetailOnExternalUrl
    publicationDate
    location
    companyG2Id
    impressionListCategory
    impressionListVariant
    salaryMinIntact
    salaryMaxIntact
    currencyName
    salaryPeriodName
    externalDetailLinkLabel
    brandForGoogleAnalytics
    suitableForOptions
  }
`;

export const JOB_DESCRIPTION_LINK_WITH_PROFILE_INFO_FRAGMENT = gql`
  fragment JobDescriptionLinkWithProfileInfoFragment on JobDescriptionLinkWithProfileInfo {
    profileDetailUrl
    profileLogoUrl
    profileMotto
    impressionListCompanyVariant
    profileName
    hasFairEmployerBadge
    profileId
    countOfReviews
    jobDescriptionLink {
      ...JobDescriptionLinkFragment
    }
  }
  ${JOB_DESCRIPTION_LINK_FRAGMENT}
`;
