import classNames from 'classnames';
import React from 'react';
import { useUser } from '@hooks/useUser';
import UserSvg from '@icons/user-trimmed.svg';
import classes from './HamburgerMenu.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {}

const HamburgerMenuUserIcon = ({ className, ...divProps }: Props) => {
  const user = useUser();

  return (
    <div {...divProps} className={classNames(className, classes.HamburgerMenu__user)} title={user?.email}>
      <UserSvg />
    </div>
  );
};

export default HamburgerMenuUserIcon;
